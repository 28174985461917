import { useState } from 'react';
import NaturalPersonCreationContainer from '../NaturalPerson/NaturalPersonCreation';
import AddressContainer from 'components/Address/Address';
import { Heading2 } from 'components/Heading2';
import { Documents } from 'components/Documents';
import { CompanyDocuments, EClientType } from 'types/Company';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import theme from 'theme/theme';
import { StepHeader } from '../styles';
import { Button, Col, DatePicker, Flex, Form, Input, Row, Select } from 'antd';
import { TextM, TextS } from 'components/Text';
import {
	IdDocumentDescription,
	RepresentativeDocumentsList,
	RepresentativeRoleDescription,
} from 'constants/representatives';
import { BaseRepresentative, RepresentativeRole } from 'types/Representative';
import { invalidCaractereRule, maxTodayDateRule } from 'helpers/rules';
import { IdDocumentType } from 'types/IdDocument';
import dayjs from 'dayjs';
import { Phone } from 'types/Phone';
import { RepresentativeCard } from './RepresentativeCard';
import AddRegisterIcon from 'components/Icons/AddRegisterIcon';
import { validateRepresentativeForm } from '../helpers';

interface IRepresentativesContainerProps {
	clientType: EClientType;
	representative: BaseRepresentative[];
	setRepresentative: (info: BaseRepresentative) => void;
}

const RepresentativesContainer = ({
	clientType,
	representative,
	setRepresentative,
}: IRepresentativesContainerProps) => {
	const [form] = Form.useForm();

	const {
		setHasRepresentative,
		hasRepresentative,
		registerRepresentative,
		setRegisterRepresentative,
		handleRemoveRepresentative,
	} = useEscrowAccountsContext();

	const [uploadDocuments, setUploadDocuments] = useState<CompanyDocuments>(
		{} as CompanyDocuments,
	);

	const isPF = clientType === EClientType.PF;

	const changeSelectorHandler = (value: string) => {
		setHasRepresentative(value);

		if (value === 'Não') {
			setRegisterRepresentative({} as BaseRepresentative);
		}
	};

	const handleSetRepresentative = (
		representativeInfo: BaseRepresentative,
	) => {
		setRepresentative({ ...registerRepresentative, ...representativeInfo });
		setRegisterRepresentative({} as BaseRepresentative);
		setUploadDocuments({} as CompanyDocuments);
		form.resetFields();
	};

	return (
		<>
			<StepHeader>
				<Heading2
					style={{
						color: theme.primary,
					}}
				>
					{isPF
						? 'Procuradores Cadastrados'
						: 'Representantes Cadastrados'}
				</Heading2>
			</StepHeader>

			{representative && representative?.length > 0 ? (
				representative.map(value => (
					<RepresentativeCard
						key={value.taxpayer_id}
						representative={value}
						handleEdit={selectedRepresentative => {
							setRegisterRepresentative(selectedRepresentative);
							changeSelectorHandler('Sim');
							setUploadDocuments(
								selectedRepresentative.documents,
							);
							form.setFieldsValue({
								sign_contract:
									selectedRepresentative.sign_contract,
								role: selectedRepresentative.role,
								id_document: {
									type: selectedRepresentative.id_document
										.type,
									number: selectedRepresentative.id_document
										.number,
									issue_date:
										selectedRepresentative.id_document
											.issue_date &&
										dayjs(
											selectedRepresentative.id_document
												.issue_date,
											'YYYY-MM-DD',
										),
									issuer: selectedRepresentative.id_document
										.issuer,
								},
							});
						}}
						handleDelete={selectedRepresentative =>
							handleRemoveRepresentative(
								selectedRepresentative.taxpayer_id,
							)
						}
					/>
				))
			) : (
				<TextM>
					Nenhum {isPF ? 'procurador' : 'representante'} cadastrado
				</TextM>
			)}

			<StepHeader
				style={{
					marginTop: '20px',
				}}
			>
				<Heading2
					style={{
						color: theme.primary,
					}}
				>
					{isPF ? 'Procurador' : 'Representante'}
				</Heading2>
			</StepHeader>

			{isPF && (
				<Flex vertical>
					<TextS
						color={theme.textSecondary}
						style={{ paddingBottom: '10px' }}
					>
						Adicionar procurador?
					</TextS>
					<Select
						placeholder="Selecione"
						defaultValue={hasRepresentative}
						value={hasRepresentative}
						onChange={changeSelectorHandler}
						style={{ marginBottom: '10px' }}
						options={[
							{
								label: 'Sim',
								value: 'Sim',
							},
							{
								label: 'Não',
								value: 'Não',
							},
						]}
					/>
				</Flex>
			)}
			{(!isPF || (isPF && hasRepresentative === 'Sim')) && (
				<>
					<NaturalPersonCreationContainer
						company={registerRepresentative}
						isRepresentativeFormCreation
						setCompany={values => {
							setRegisterRepresentative({
								...values,
								full_name: values?.name || '',
								id_document: registerRepresentative.id_document,
								role: registerRepresentative.role,
								sign_contract:
									registerRepresentative.sign_contract,
								documents: registerRepresentative.documents,
								address: registerRepresentative.address,
								birth_date: values.birth_date || '',
								email_address: values.email_address || '',
								mother_name: values.mother_name || '',
								pep: values.pep || false,
								phone: values.phone || ({} as Phone),
								taxpayer_id: values.taxpayer_id || '',
							});
						}}
					/>
					<Form
						form={form}
						style={{ width: '100%' }}
						initialValues={
							{
								...registerRepresentative,
								birth_date:
									registerRepresentative?.birth_date &&
									dayjs(
										registerRepresentative?.birth_date,
										'YYYY-MM-DD',
									),
								id_document: {
									...registerRepresentative.id_document,
									issue_date:
										registerRepresentative?.id_document
											?.issue_date &&
										dayjs(
											registerRepresentative?.id_document
												.issue_date,
											'YYYY-MM-DD',
										),
								},
							} as BaseRepresentative
						}
						onFinish={values => {
							handleSetRepresentative({
								...registerRepresentative,
								...values,
							});

							setHasRepresentative('Não');
							form.resetFields();
							form.setFieldsValue({
								sign_contract: undefined,
								role: undefined,
								id_document: {
									type: undefined,
									number: undefined,
									issue_date: undefined,
									issuer: undefined,
								},
							});
						}}
					>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Flex vertical>
									<TextS
										color={theme.textSecondary}
										style={{ paddingBottom: '10px' }}
									>
										Assina contrato?
									</TextS>
									<Form.Item
										name="sign_contract"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<Select
											placeholder="Selecione"
											onChange={e => {
												setRegisterRepresentative({
													...registerRepresentative,
													sign_contract: e,
												});
											}}
											options={[
												{ label: 'Sim', value: true },
												{ label: 'Não', value: false },
											]}
										/>
									</Form.Item>
								</Flex>
							</Col>
							<Col span={12}>
								<Flex vertical>
									<TextS
										color={theme.textSecondary}
										style={{ paddingBottom: '10px' }}
									>
										Cargo
									</TextS>
									<Form.Item
										name="role"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<Select
											placeholder="Selecione"
											onChange={e => {
												setRegisterRepresentative({
													...registerRepresentative,
													role: e,
												});
											}}
											options={Object.keys(
												RepresentativeRoleDescription,
											).map(key => ({
												label: RepresentativeRoleDescription[
													key as unknown as RepresentativeRole
												],
												value: key,
											}))}
										/>
									</Form.Item>
								</Flex>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Flex vertical>
									<TextS
										color={theme.textSecondary}
										style={{ paddingBottom: '10px' }}
									>
										Tipo de documento
									</TextS>
									<Form.Item
										name={['id_document', 'type']}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<Select
											placeholder="Selecione"
											onChange={e => {
												setRegisterRepresentative({
													...registerRepresentative,
													id_document: {
														...registerRepresentative.id_document,
														type: e,
													},
												});
											}}
											options={Object.keys(
												IdDocumentDescription,
											).map(key => ({
												label: IdDocumentDescription[
													key as unknown as IdDocumentType
												],
												value: key,
											}))}
										/>
									</Form.Item>
								</Flex>
							</Col>
							<Col span={12}>
								<Flex vertical>
									<TextS
										color={theme.textSecondary}
										style={{ paddingBottom: '10px' }}
									>
										Número do documento
									</TextS>
									<Form.Item
										name={['id_document', 'number']}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											invalidCaractereRule(),
										]}
									>
										<Input
											placeholder="Digite aqui"
											onChange={e => {
												setRegisterRepresentative({
													...registerRepresentative,
													id_document: {
														...registerRepresentative.id_document,
														number: e.target.value,
													},
												});
											}}
										/>
									</Form.Item>
								</Flex>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Flex vertical>
									<TextS
										color={theme.textSecondary}
										style={{ paddingBottom: '10px' }}
									>
										Data de emissão
									</TextS>
									<Form.Item
										name={['id_document', 'issue_date']}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											maxTodayDateRule('issue_date'),
										]}
									>
										<DatePicker
											placeholder="DD/MM/AAAA"
											format="DD/MM/YYYY"
											style={{ width: '100%' }}
											onChange={e => {
												setRegisterRepresentative({
													...registerRepresentative,
													id_document: {
														...registerRepresentative.id_document,
														issue_date:
															dayjs(e).format(
																'YYYY-MM-DD',
															),
													},
												});
											}}
										/>
									</Form.Item>
								</Flex>
							</Col>
							<Col span={12}>
								<Flex vertical>
									<TextS
										color={theme.textSecondary}
										style={{ paddingBottom: '10px' }}
									>
										Emissor
									</TextS>
									<Form.Item
										name={['id_document', 'issuer']}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											invalidCaractereRule(),
										]}
									>
										<Input
											placeholder="Digite aqui"
											onChange={e => {
												setRegisterRepresentative({
													...registerRepresentative,
													id_document: {
														...registerRepresentative.id_document,
														issuer: e.target.value,
													},
												});
											}}
										/>
									</Form.Item>
								</Flex>
							</Col>
						</Row>
					</Form>
					<br />
					<AddressContainer
						address={registerRepresentative?.address}
						setAddress={address =>
							setRegisterRepresentative({
								...registerRepresentative,
								address: address,
							})
						}
					/>
					<Documents
						hideTitle
						isCreation
						documentsObject={uploadDocuments}
						documentsList={RepresentativeDocumentsList}
						onChangeDocumentsObject={documents => {
							setUploadDocuments({
								...uploadDocuments,
								...documents,
							});
							setRegisterRepresentative({
								...registerRepresentative,
								documents: documents,
							});
						}}
					/>
					<br />

					<Flex
						justify="flex-end"
						style={{ marginTop: '20px', marginBottom: '20px' }}
					>
						<Button
							type="default"
							style={{
								backgroundColor: theme.primary,
								borderColor: theme.primary,
								width: 'auto',
								marginTop: '20px',
								opacity: !validateRepresentativeForm()
									? 0.5
									: 1,
							}}
							onClick={form.submit}
							disabled={!validateRepresentativeForm()}
						>
							<TextS color={theme.white}>
								Adicionar{' '}
								{isPF ? 'procurador' : 'representante'}
							</TextS>
							<AddRegisterIcon />
						</Button>
					</Flex>
				</>
			)}
		</>
	);
};

export default RepresentativesContainer;
