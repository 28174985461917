import { PersonIcon } from 'components/Icons/Person';
import { IconContainer, RepresentativeCardContainer } from './styles';
import { Button, Descriptions, Flex, Row } from 'antd';
import theme from 'theme/theme';
import { TextM, TextS } from 'components/Text';
import { FaRegTrashCan } from 'react-icons/fa6';
import { RiPencilLine } from 'react-icons/ri';
import { BaseRepresentative } from 'types/Representative';
import { normalizeCpf } from 'helpers/normalizers';

export interface IRepresentativeCardProps {
	representative: BaseRepresentative;
	handleEdit?: (representative: BaseRepresentative) => void;
	handleDelete?: (representative: BaseRepresentative) => void;
}

export interface IDescriptionsItemsProps {
	item: BaseRepresentative;
}

const DescriptionsItems = ({ item }: IDescriptionsItemsProps) => {
	return (
		<Flex style={{ marginTop: 16 }} gap={54} align="center">
			<div>
				<TextS color={theme.textGray}>CPF</TextS>
				<TextS weight="bold">{normalizeCpf(item?.taxpayer_id)}</TextS>
			</div>
			<div>
				<TextS color={theme.textGray}>Email</TextS>
				<TextS weight="bold">{item?.email_address ?? '-'}</TextS>
			</div>
		</Flex>
	);
};

const RepresentativeCard = ({
	representative,
	handleEdit,
	handleDelete,
}: IRepresentativeCardProps) => {
	return (
		<RepresentativeCardContainer>
			<Flex justify="space-between" align="center">
				<Flex gap={16} align="center">
					<IconContainer>
						<PersonIcon color={theme.white} />
					</IconContainer>
					<TextM weight="bold">
						{representative?.full_name ?? '-'}
					</TextM>
				</Flex>
				<Flex gap={8} align="center">
					<Button
						onClick={() => handleEdit?.(representative)}
						type="default"
						style={{
							backgroundColor: 'transparent',
							borderColor: theme.grayLight,
						}}
					>
						<RiPencilLine
							size={18}
							style={{ color: theme.primary }}
						/>
					</Button>
					<Button
						onClick={() => handleDelete?.(representative)}
						type="default"
						style={{
							backgroundColor: 'transparent',
							borderColor: theme.dangerBackground,
						}}
					>
						<FaRegTrashCan style={{ color: theme.danger }} />
					</Button>
				</Flex>
			</Flex>
			<DescriptionsItems item={representative} />
		</RepresentativeCardContainer>
	);
};

export default RepresentativeCard;
