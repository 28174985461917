import { useCallback, useEffect, useState } from 'react';
import { Flex, Select, notification } from 'antd';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';

// Components
import { Documents } from 'components/Documents';
import { Heading2 } from 'components/Heading2';
import { Modal } from 'components/Modal';
import { TextS } from 'components/Text';
import AddressContainer from 'components/Address/Address';
import LegalPersonContainer from './LegalPerson/LegalPerson';
import NaturalPersonCreationContainer from './NaturalPerson/NaturalPersonCreation';
import RepresentativesContainer from './Representatives/Representatives';
import StepsButtonComponent from 'modules/escrow/components/StepsButton/StepsButton';
import StepsComponent from './Steps/Steps';

// Hooks and services
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import { PersonsService } from 'modules/company/services';

// Types and helpers
import { ApiError } from 'types/ApiError';
import { isPersonOrCompany } from 'helpers/validators';
import { BaseRepresentative, Representative } from 'types/Representative';
import {
	validateAddressForm,
	validateDocumentForm,
	validateInformationForm,
	validateLegalDocumentForm,
	validateLegalInformationForm,
	validateRepresentativeForm,
} from './helpers';
import {
	serializeOnlyNumbers,
	serializePhoneObject,
} from 'helpers/serializers';
import {
	CompanyDocumentsList,
	naturalPersonDocumentsList,
} from 'constants/company';
import {
	BusinessType,
	CompanyDocuments,
	CompanyDto,
	EClientType,
	PersonType,
} from 'types/Company';

// Styles
import theme from 'theme/theme';
import { FormThemeProvider } from 'theme/FormThemeProvider';
import { StepContainer, StepHeader, StepsContainer } from './styles';

export const enum ECreationSteps {
	INFORMATION = 'INFORMATION',
	ADDRESS = 'ADDRESS',
	DOCUMENT = 'DOCUMENT',
	REPRESENTATIVES = 'REPRESENTATIVES',
}

interface ICompanyCreationModalProps {
	isOpen: boolean;
	onFinishHandler: () => void;
	onCloseHandler: () => void;
	isAccountCreation?: boolean;
	taxId?: string;
}

const CompanyCreationModal = ({
	isOpen,
	onFinishHandler,
	onCloseHandler,
	isAccountCreation,
	taxId,
}: ICompanyCreationModalProps) => {
	/* Hooks */
	const [api, contextHolder] = notification.useNotification();
	const {
		company,
		setCompany,
		resetEscrowContext,
		representative,
		setRepresentative,
		hasRepresentative,
		setRelatedParts,
		handleClearRepresentative,
	} = useEscrowAccountsContext();

	/* States */
	const [clientType, setClientType] = useState<EClientType>(
		isPersonOrCompany(company?.taxpayer_id) || EClientType.PF,
	);
	const [currentStep, setCurrentStep] = useState<ECreationSteps>(
		ECreationSteps.INFORMATION,
	);
	const [uploadDocuments, setUploadDocuments] = useState<CompanyDocuments>(
		{} as CompanyDocuments,
	);

	const isPF = clientType === EClientType.PF;

	const backStepHandler = useCallback(() => {
		const nextStep = {
			[ECreationSteps.INFORMATION]: ECreationSteps.INFORMATION,
			[ECreationSteps.ADDRESS]: ECreationSteps.INFORMATION,
			[ECreationSteps.DOCUMENT]: ECreationSteps.ADDRESS,
			[ECreationSteps.REPRESENTATIVES]: ECreationSteps.DOCUMENT,
		};

		setCurrentStep(nextStep[currentStep]);
	}, [currentStep]);

	const clearAndClose = () => {
		setClientType(EClientType.PF);
		setCurrentStep(ECreationSteps.INFORMATION);
		setUploadDocuments({} as CompanyDocuments);
		resetEscrowContext();
		setRepresentative(undefined);
		onCloseHandler();
	};

	const naturalPersonMutation = useMutation<
		{ id: string },
		ApiError,
		CompanyDto
	>({
		mutationFn: newCompany => {
			return PersonsService.createCompany({
				...newCompany,
				person_type: PersonType.NATURAL,
				business_type: newCompany?.business_type || BusinessType.OTHERS,
				documents: {
					proof_of_address: newCompany?.documents?.proof_of_address,
					document_identification:
						newCompany?.documents?.identification,
				},
				representatives: !!newCompany?.representatives?.[0]?.taxpayer_id
					? newCompany?.representatives?.map(representative => ({
							...representative,
							documents: {
								identification:
									representative.documents.identification,
								proof_of_address:
									representative.documents.proof_of_address,
							},
						}))
					: [],
			});
		},
		onSuccess: ({ id }) => {
			if (isAccountCreation) {
				setCompany({
					...company,
					id: id,
					representatives: representative
						? representative?.length
							? representative
							: []
						: [],
				});
				setRelatedParts([
					{
						permission: [],
						person_id: id,
						relationship: BusinessType.OTHERS,
						person: {
							...company,
							creator: {
								full_name: company?.name || '',
								id: company?.id || '',
								taxpayer_id: company?.taxpayer_id || '',
							},
							id: id,
							representatives: representative
								? representative?.length
									? representative
									: []
								: [],
						},
						sign_contract: true,
					},
				]);
			}

			setTimeout(() => {
				onFinishHandler();
				if (!isAccountCreation) {
					resetEscrowContext();
					clearAndClose();
				}
			}, 1500);

			api.success({
				description: 'O cliente foi criado com sucesso!',
				message: 'Criado com sucesso.',
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao criar a empresa. ',
			});
		},
	});

	const legalPersonMutation = useMutation<
		{ id: string },
		ApiError,
		CompanyDto
	>({
		mutationFn: newCompany => {
			return PersonsService.createCompany({
				...newCompany,
				person_type: PersonType.LEGAL,
				business_type: newCompany?.business_type || BusinessType.OTHERS,
				documents: {
					bylaws: newCompany?.documents?.bylaws,
					income_statement: newCompany?.documents?.income_statement,
					incorporation_certificate:
						newCompany?.documents?.incorporation_certificate,
					last_contract_amendment:
						newCompany?.documents?.last_contract_amendment,
					proof_of_address: newCompany?.documents?.proof_of_address,
					power_of_attorney: newCompany?.documents?.power_of_attorney,
				},
				representatives: newCompany?.representatives?.map(
					representative => ({
						...representative,
						documents: {
							identification:
								representative.documents?.identification,
							proof_of_address:
								representative.documents?.proof_of_address,
						},
						representative: representative?.representative && {
							...representative.representative,
							documents: {
								identification:
									representative.representative.documents
										.identification,
								proof_of_address:
									representative.representative.documents
										.proof_of_address,
							},
						},
					}),
				),
			});
		},
		onSuccess: ({ id }) => {
			if (isAccountCreation) {
				setCompany({
					...company,
					id: id,
					representatives: representative
						? representative?.length
							? representative
							: []
						: [],
				});
				setRelatedParts([
					{
						permission: [],
						person_id: id,
						relationship: BusinessType.OTHERS,
						person: {
							...company,
							creator: {
								full_name: company?.name || '',
								id: company?.id || '',
								taxpayer_id: company?.taxpayer_id || '',
							},
							id: id,
							representatives: representative
								? representative?.length
									? representative
									: []
								: [],
						},
						sign_contract: true,
					},
				]);
			}

			setTimeout(() => {
				onFinishHandler();
				if (!isAccountCreation) {
					resetEscrowContext();
					clearAndClose();
				}
			}, 1500);

			api.success({
				description: 'O cliente foi criado com sucesso!',
				message: 'Criado com sucesso.',
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao criar a empresa. ',
			});
		},
	});

	const nextStepHandler = useCallback(() => {
		if (currentStep === ECreationSteps.REPRESENTATIVES) {
			if (isPF && company) {
				return naturalPersonMutation.mutate({
					...company,
					phone: serializePhoneObject(
						company.phone as unknown as string,
					),
					taxpayer_id: serializeOnlyNumbers(company.taxpayer_id),
					birth_date: dayjs(company.birth_date).format('YYYY-MM-DD'),
					address: {
						...company.address,
						country_code: 'BRZ',
						postal_code: serializeOnlyNumbers(
							company?.address?.postal_code,
						),
					},
					representatives: representative?.map(representative => ({
						...representative,
						taxpayer_id: serializeOnlyNumbers(
							representative?.taxpayer_id,
						),
						birth_date: dayjs(representative.birth_date).format(
							'YYYY-MM-DD',
						),
						phone: serializePhoneObject(
							representative.phone as unknown as string,
						),
						address: {
							...representative.address,
							country_code: 'BRZ',
							postal_code: serializeOnlyNumbers(
								representative?.address?.postal_code,
							),
						},
						id_document: {
							...representative.id_document,
							issue_date: dayjs(
								representative.id_document?.issue_date,
							).format('YYYY-MM-DD'),
						},
					})),
				});
			}

			if (!isPF && company) {
				return legalPersonMutation.mutate({
					...company,
					phone: serializePhoneObject(
						company.phone as unknown as string,
					),
					taxpayer_id: serializeOnlyNumbers(company.taxpayer_id),
					foundation_date: dayjs(company.foundation_date).format(
						'YYYY-MM-DD',
					),
					address: {
						...company.address,
						country_code: 'BRZ',
						postal_code: serializeOnlyNumbers(
							company?.address?.postal_code,
						),
					},
					representatives: representative?.map(representative => ({
						...representative,
						taxpayer_id: serializeOnlyNumbers(
							representative?.taxpayer_id,
						),
						birth_date: dayjs(representative.birth_date).format(
							'YYYY-MM-DD',
						),
						phone: serializePhoneObject(
							representative.phone as unknown as string,
						),
						address: {
							...representative.address,
							country_code: 'BRZ',
							postal_code: serializeOnlyNumbers(
								representative?.address?.postal_code,
							),
						},
						id_document: {
							...representative.id_document,
							issue_date: dayjs(
								representative.id_document?.issue_date,
							).format('YYYY-MM-DD'),
						},
					})),
				});
			}

			api.error({
				description:
					'Verifique se todas as informações estão preenchidas!',
				message: 'Ocorreu um problema ao criar a empresa. ',
			});
		}

		const nextStep = {
			[ECreationSteps.INFORMATION]: ECreationSteps.ADDRESS,
			[ECreationSteps.ADDRESS]: ECreationSteps.DOCUMENT,
			[ECreationSteps.DOCUMENT]: ECreationSteps.REPRESENTATIVES,
			[ECreationSteps.REPRESENTATIVES]: ECreationSteps.REPRESENTATIVES,
		};

		setCurrentStep(nextStep[currentStep]);
	}, [currentStep, company, representative]);

	const naturalDocuments = (
		<Documents
			hideTitle
			isCreation
			documentsObject={uploadDocuments}
			documentsList={naturalPersonDocumentsList}
			onChangeDocumentsObject={documents => {
				setUploadDocuments({
					...uploadDocuments,
					...documents,
				});
				setCompany({
					...company,
					documents: documents,
				});
			}}
		/>
	);

	const legalDocuments = (
		<Documents
			hideTitle
			isCreation
			documentsObject={uploadDocuments}
			documentsList={CompanyDocumentsList}
			onChangeDocumentsObject={documents => {
				setUploadDocuments({
					...uploadDocuments,
					...documents,
				});
				setCompany({
					...company,
					documents: documents,
				});
			}}
		/>
	);

	const informationComponent = (
		<>
			<Flex vertical style={{ marginBottom: '10px' }}>
				<StepHeader>
					<Heading2
						style={{
							color: theme.primary,
						}}
					>
						Informações básicas
					</Heading2>
				</StepHeader>
				<Flex vertical>
					<TextS
						color={theme.textSecondary}
						style={{ paddingBottom: '10px' }}
					>
						Tipo de cliente:
					</TextS>
					<Select
						placeholder="Selecione"
						defaultValue={clientType}
						onChange={e => {
							setClientType(e);
							setCompany({} as CompanyDto);
							setRelatedParts([]);
						}}
						style={{ marginBottom: '10px' }}
						options={[
							{
								label: 'Pessoa física',
								value: EClientType.PF,
							},
							{
								label: 'Pessoa jurídica',
								value: EClientType.PJ,
							},
						]}
					/>
				</Flex>
			</Flex>
			{isPF ? (
				<NaturalPersonCreationContainer
					company={company}
					setCompany={setCompany}
					taxId={taxId}
				/>
			) : (
				<LegalPersonContainer
					company={company}
					setCompany={setCompany}
					taxId={taxId}
				/>
			)}
		</>
	);

	const steps = {
		[ECreationSteps.INFORMATION]: informationComponent,
		[ECreationSteps.ADDRESS]: (
			<AddressContainer
				address={company?.address}
				setAddress={address =>
					setCompany({ ...company, address: address })
				}
			/>
		),
		[ECreationSteps.DOCUMENT]: isPF ? naturalDocuments : legalDocuments,
		[ECreationSteps.REPRESENTATIVES]: (
			<RepresentativesContainer
				clientType={isPF ? EClientType.PF : EClientType.PJ}
				representative={representative || []}
				setRepresentative={setRepresentative}
			/>
		),
	};

	const isInfoStepDisabled = isPF
		? !validateInformationForm()
		: !validateLegalInformationForm() &&
			company?.pep === undefined &&
			company?.business_type === undefined;

	const isDocStepDisabled = isPF
		? !validateDocumentForm(company?.documents)
		: !validateLegalDocumentForm(company?.documents);

	const isRepresentativesDisabled: boolean =
		hasRepresentative === 'Sim' || !isPF
			? !validateRepresentativeForm()
			: false;

	useEffect(() => {
		//clear
		setCurrentStep(ECreationSteps.INFORMATION);
		setRepresentative(undefined);
		setUploadDocuments({} as CompanyDocuments);
		handleClearRepresentative();

		if (company?.taxpayer_id) {
			setClientType(isPF ? EClientType.PF : EClientType.PJ);
		}
	}, []);

	return (
		<Modal
			isOpen={isOpen}
			width={1000}
			height={700}
			title="Adicionar cliente"
			onClose={clearAndClose}
		>
			{contextHolder}
			<StepsContainer>
				<Flex style={{ width: '100%' }}>
					<StepsComponent
						currentStep={currentStep}
						isPF={isPF}
						isCompleted={{
							isAddressCompleted: validateAddressForm(),
							isDocumentsCompleted: isPF
								? validateDocumentForm(company?.documents)
								: validateLegalDocumentForm(company?.documents),
							isInfoCompleted: isPF
								? validateInformationForm() &&
									company?.pep !== undefined
								: validateLegalInformationForm(),
						}}
					/>

					<StepContainer>
						<FormThemeProvider>
							{steps[currentStep]}
						</FormThemeProvider>
					</StepContainer>
				</Flex>
			</StepsContainer>

			<StepsButtonComponent
				backStep={backStepHandler}
				isFirstStep={currentStep !== ECreationSteps.INFORMATION}
				isLastStep={currentStep === ECreationSteps.REPRESENTATIVES}
				lastStepButtonText="Concluir cadastro"
				nextStep={nextStepHandler}
				isDisabled={
					(currentStep === ECreationSteps.INFORMATION &&
						isInfoStepDisabled) ||
					(currentStep === ECreationSteps.ADDRESS &&
						!validateAddressForm()) ||
					(currentStep === ECreationSteps.DOCUMENT &&
						isDocStepDisabled) ||
					(currentStep === ECreationSteps.REPRESENTATIVES &&
						!isPF &&
						representative?.length === 0)
				}
			/>
		</Modal>
	);
};

export default CompanyCreationModal;
