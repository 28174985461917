import { createContext, useContext, useMemo, useState } from 'react';
import { BusinessType, Company, CompanyDto } from 'types/Company';
import { RelatedPartType } from '../components/RelatedPartsCreation/RelatedPartsCreation';
import { AccountPermission } from 'types/Account';
import { AccessType } from 'types/Access';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccountOwnerPermissions } from 'constants/account';
import { BaseRepresentative } from 'types/Representative';

type TSkipContractState = {
	requester: boolean;
	customer: boolean;
};

interface IEscrowAccountsContext {
	company?: CompanyDto;
	hasRepresentative: string;
	isCreationInfoComplete: boolean;
	isEditingAccount: boolean;
	isFormDisabled: boolean;
	isInformationFormValid: boolean;
	isPersonCreating: boolean;
	isPFDocumentsValid?: boolean;
	isPJDocumentsValid?: boolean;
	relatedParts: RelatedPartType[];
	representative?: BaseRepresentative[];
	registerRepresentative: BaseRepresentative;
	resetEscrowContext: () => void;
	setCompany: (company: CompanyDto) => void;
	setHasRepresentative: (value: string) => void;
	setIsEditingAccount: (isEditingAccount: boolean) => void;
	setIsFormDisabled: (isDisabled: boolean) => void;
	setIsInformationFormValid: (value: boolean) => void;
	setIsPersonCreating: (isPersonCreating: boolean) => void;
	setRelatedParts: (parts: RelatedPartType[]) => void;
	setRepresentative: (representative?: BaseRepresentative) => void;
	hasSkipContract: TSkipContractState;
	setHasSkipContract: (value: TSkipContractState) => void;
	setRegisterRepresentative: (value?: BaseRepresentative) => void;
	handleRemoveRepresentative: (taxpayer_id: string) => void;
	handleClearRepresentative: () => void;
}

const EscrowAccountsContext = createContext<IEscrowAccountsContext>(
	{} as IEscrowAccountsContext,
);

const EscrowProvider = ({ children }: IChildrenProps) => {
	/* Hooks */
	const { person: personInContext, type } = useCurrentProfile();

	/* States */
	const [hasRepresentative, setHasRepresentative] = useState('Não');
	const [isEditingAccount, setIsEditingAccount] = useState<boolean>(false);
	const [isPersonCreating, setIsPersonCreating] = useState<boolean>(false);
	const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);
	const [representative, setRepresentative] = useState<BaseRepresentative[]>(
		[] as BaseRepresentative[],
	);
	const [registerRepresentative, setRegisterRepresentative] =
		useState<BaseRepresentative>({} as BaseRepresentative);
	const [hasSkipContract, setHasSkipContract] = useState<TSkipContractState>({
		customer: false,
		requester: false,
	});

	const [isInformationFormValid, setIsInformationFormValid] =
		useState<boolean>(false);
	const [company, setCompany] = useState<Company | CompanyDto>(
		{} as CompanyDto,
	);

	/* Related Parts Variables */
	const companyRelatedPart: RelatedPartType = {
		person_id: company?.id ?? 'company_owner_id',
		permission: AccountOwnerPermissions,
		relationship: BusinessType.OTHERS,
		person: company as Company,
		sign_contract: false,
	};

	const currentPersonProfile: RelatedPartType = {
		person_id: personInContext?.id ?? '',
		permission: [AccountPermission.VIEW_ACCOUNT],
		relationship: BusinessType.OTHERS,
		sign_contract: false,
	};

	const mustShowInRelatedPartList =
		type === AccessType.FINANCIAL_INSTITUTION
			? [companyRelatedPart]
			: [companyRelatedPart, currentPersonProfile];

	const [relatedParts, setRelatedParts] = useState<RelatedPartType[]>([
		...(company?.name && company?.taxpayer_id
			? mustShowInRelatedPartList
			: []),
	]);

	/* Validations */
	const isPJDocumentsValid = useMemo(() => {
		return (
			company?.documents &&
			typeof company?.documents['income_statement_url'] === 'string' &&
			typeof company.documents['incorporation_certificate_url'] ===
				'string' &&
			typeof company.documents['last_contract_amendment_url'] ===
				'string' &&
			typeof company.documents['proof_of_address_url'] === 'string'
		);
	}, [company?.documents]);

	const isPFDocumentsValid = useMemo(() => {
		return (
			company?.documents &&
			typeof company.documents['identification_url'] === 'string' &&
			typeof company.documents['proof_of_address_url'] === 'string'
		);
	}, [company?.documents]);

	const isCreationInfoComplete: boolean =
		!!isInformationFormValid ||
		!!isPFDocumentsValid ||
		!!isPJDocumentsValid ||
		!!company?.representatives?.length ||
		!!relatedParts.length;

	/* Handlers */
	const resetEscrowContext = () => {
		setHasSkipContract({} as TSkipContractState);
		setIsEditingAccount(false);
		setIsPersonCreating(false);
		setCompany({} as CompanyDto);
		setRelatedParts([]);
		setIsFormDisabled(false);
		setRepresentative([]);
		setHasRepresentative('Não');
		setRegisterRepresentative({} as BaseRepresentative);
		handleClearRepresentative();
	};

	const upsertRepresentative = (newRepresentative?: BaseRepresentative) => {
		if (!newRepresentative) return;

		//existing by taxpayer_id
		const existingRepresentative = representative.find(
			rep => rep.taxpayer_id === newRepresentative.taxpayer_id,
		);

		if (existingRepresentative) {
			const updatedRepresentative = representative.map(rep =>
				rep.taxpayer_id === newRepresentative.taxpayer_id
					? { ...rep, ...newRepresentative }
					: rep,
			);
			setRepresentative(updatedRepresentative);
		} else {
			setRepresentative([...representative, newRepresentative]);
		}
	};

	const handleRegisterRepresentative = (
		newRepresentative: BaseRepresentative | undefined,
	): void => {
		if (!newRepresentative) {
			setRegisterRepresentative(null as unknown as BaseRepresentative);
			return;
		}

		setRegisterRepresentative(newRepresentative);
	};

	const handleRemoveRepresentative = (taxpayer_id: string): void => {
		const updatedRepresentative = representative.filter(
			rep => rep.taxpayer_id !== taxpayer_id,
		);

		setRepresentative(updatedRepresentative);
	};

	const handleClearRepresentative = () => {
		setRepresentative([]);
	};

	return (
		<EscrowAccountsContext.Provider
			value={{
				company,
				hasRepresentative,
				isCreationInfoComplete,
				isEditingAccount,
				isFormDisabled,
				isInformationFormValid,
				isPersonCreating,
				isPFDocumentsValid,
				isPJDocumentsValid,
				relatedParts,
				representative,
				registerRepresentative,
				resetEscrowContext,
				setCompany,
				setHasRepresentative,
				setIsEditingAccount,
				setIsFormDisabled,
				setIsInformationFormValid,
				setIsPersonCreating,
				setRelatedParts,
				setRepresentative: upsertRepresentative,
				hasSkipContract,
				setHasSkipContract,
				setRegisterRepresentative: handleRegisterRepresentative,
				handleRemoveRepresentative,
				handleClearRepresentative,
			}}
		>
			{children}
		</EscrowAccountsContext.Provider>
	);
};

const useEscrowAccountsContext = () => {
	const context = useContext(EscrowAccountsContext);
	return context;
};

export { useEscrowAccountsContext, EscrowProvider as default };
