import React from 'react';

const AddRegisterIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={15} height={16} fill="none">
		<path
			fill={color ?? '#F4F4FF'}
			d="M7.5 10.189v1.567A4.5 4.5 0 0 0 1.5 16H0a6 6 0 0 1 7.5-5.811ZM6 9.25a4.499 4.499 0 0 1-4.5-4.5C1.5 2.264 3.514.25 6 .25s4.5 2.014 4.5 4.5-2.014 4.5-4.5 4.5Zm0-1.5a3 3 0 1 0 0-6 3 3 0 1 0 0 6Zm4.5 4.5V10H12v2.25h2.25v1.5H12V16h-1.5v-2.25H8.25v-1.5h2.25Z"
		/>
	</svg>
);
export default AddRegisterIcon;
