import styled from 'styled-components';

export const RepresentativeCardContainer = styled.div`
	width: 100%;
	background-color: ${({ theme }) => theme.primaryOpacity};
	padding: 16px;
	border-radius: 8px;
	margin-top: 8px;
`;

export const IconContainer = styled.div`
	width: 30px;
	padding: 6px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.primary};
`;
