import { useEffect, useState } from 'react';
import { Col, Form, Input, Row, Select, Spin, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import { Heading2 } from 'components/Heading2';
import { statesList } from 'constants/states';
import { Address, AddressViaCep } from 'types/Address';
import { normalizeCep } from 'helpers/normalizers';
import theme from 'theme/theme';
import { StepContainer, StepHeader } from './Address.styles';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { RuleRender } from 'antd/es/form';

interface IAddressContainerProps {
	address?: Address;
	setAddress: (address: Address) => void;
	hideTitle?: boolean;
}

const AddressContainer = ({
	address,
	setAddress,
	hideTitle,
}: IAddressContainerProps) => {
	/* Hooks */
	const [form] = useForm();
	const [api, contextHolder] = notification.useNotification();

	/* States */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	//reset form
	useEffect(() => {
		if (!address) {
			form.resetFields();
		}
	}, [address]);

	const getAddress = async (cep: string) => {
		const clearedCep = cep.replace(/\D/g, '');

		try {
			setIsLoading(true);
			const response = await axios.get<AddressViaCep>(
				`https://viacep.com.br/ws/${clearedCep}/json/`,
			);

			if (!response.data.logradouro) {
				setAddress({ postal_code: cep });
				form.setFieldsValue({
					city: '',
					district: '',
					state_code: undefined,
					street_name: '',
					street_number: '',
				});
				return api.error({
					description:
						'Verifique as informações e preencha o formulário.',
					message: 'Cep não encontrado.',
				});
			}

			const viaCepAddress = response.data as AddressViaCep;

			setAddress({
				...address,
				city: viaCepAddress?.localidade || '',
				country_code: 'BRZ',
				district: viaCepAddress?.bairro,
				state_code: viaCepAddress?.uf,
				street_name: viaCepAddress?.logradouro,
				postal_code: serializeOnlyNumbers(viaCepAddress?.cep),
			});

			form.setFieldsValue({
				city: viaCepAddress.localidade || '',
				district: viaCepAddress.bairro || '',
				state_code: viaCepAddress.uf || '',
				street_name: viaCepAddress.logradouro || '',
				street_number: '',
			});
		} catch (error) {
			setAddress({ postal_code: cep });
			api.error({
				description:
					'Verifique as informações e preencha o formulário.',
				message: 'Cep não encontrado.',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const addressEmptyFieldRule =
		(fieldName: string): RuleRender =>
		({ isFieldTouched }) => ({
			validator(_, value) {
				if (
					isFieldTouched(fieldName) ||
					value ||
					String(value).split(' ').length > 1
				) {
					if (
						String(value)
							.split(' ')
							.every(name => name.length >= 1)
					) {
						return Promise.resolve();
					}
					return Promise.reject(
						new Error('Existem caracteres inválidos'),
					);
				}

				return Promise.reject(new Error('Preenchimento obrigatório'));
			},
		});

	useEffect(() => {
		if (address) {
			form.setFieldsValue(address);
		}
	}, [address]);

	return (
		<StepContainer>
			{contextHolder}

			{!hideTitle && (
				<StepHeader>
					<Heading2
						style={{
							color: theme.primary,
						}}
					>
						Endereço
					</Heading2>
				</StepHeader>
			)}
			{isLoading && <Spin fullscreen />}

			<Form<Address>
				form={form}
				style={{ width: '100%' }}
				initialValues={address as Address}
				layout="vertical"
				onFieldsChange={f => {
					setAddress({ ...address, [f[0].name]: f[0].value });
					form.isFieldTouched({
						[f[0].name]: true,
					});
				}}
			>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Form.Item
							name="postal_code"
							label="CEP"
							normalize={e => normalizeCep(e)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input
								placeholder="00000-000"
								maxLength={9}
								onChange={e =>
									e.target.value.length === 9
										? getAddress(e.target.value)
										: undefined
								}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={18}>
						<Form.Item
							name="street_name"
							label="Rua/Avenida"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								addressEmptyFieldRule('street_name'),
							]}
						>
							<Input placeholder="Digite aqui" />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							name="street_number"
							label="Número"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Digite aqui" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<Form.Item
							name="state_code"
							label="Estado"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								addressEmptyFieldRule('state_code'),
							]}
						>
							<Select
								showSearch={true}
								filterOption={(input, option) =>
									option?.label
										?.toLowerCase()
										?.startsWith(input.toLowerCase()) ||
									String(option?.value)
										?.toLowerCase()
										?.startsWith(input.toLowerCase())
								}
								placeholder="Selecione o estado"
								options={statesList}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="city"
							label="Cidade"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								addressEmptyFieldRule('city'),
							]}
						>
							<Input placeholder="Digite aqui" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<Form.Item
							name="district"
							label="Bairro"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								addressEmptyFieldRule('district'),
							]}
						>
							<Input placeholder="Digite aqui" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item name="extra_info" label="Complemento">
							<Input placeholder="Digite aqui" />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</StepContainer>
	);
};

export default AddressContainer;
